<!--
 * @Author: mjzhu
 * @Date: 2022-06-13 14:04:05
 * @LastEditTime: 2023-05-26 20:42:28
 * @FilePath: \awx-ui\src\components\steps\components\commonForm.vue
-->
<template>
  <div class="common-template steps">
    <div :class="['drag-template-item']">
      <img :src="selectedTemplateObj.icon" alt="" />
      <div class="action-content">
        <div class="action-text-wrap">
          <p class="action-label over-flow-ellipsis action-name" :title="selectedTemplateObj.name">{{selectedTemplateObj.name}}</p>
        </div>
        <div class="action-desc over-flow-ellipsis" :title="selectedTemplateObj.description">{{selectedTemplateObj.description}}</div>
      </div>
    </div>
    <div id="common-template" style="max-height: 520px; overflow-y: auto;padding-right: 64px">
      <a-form :form="form" :label-col="{span: 5}" :wrapper-col="{span: 19}" :class="['form-content', 'mgh160', isQuick ? 'qiick-content' : '']">
        <a-form-item v-if="!isQuick" label="步骤显示名称">
          <a-input v-decorator="[
          `name`,
          // { validator: checkName }
          { initialValue: selectedTemplateObj.name+'',rules: [{ required: true, message: `步骤显示名称不能为空!` }] },
        ]" placeholder="请输入" style="width: 100%" />
        </a-form-item>
      </a-form>
      <FormDesign :selectedTemplateObj="selectedTemplateObj.schema" :model="model" />
    </div>
  </div>
</template>
<script>
import { mapState, mapMutations, mapActions } from "vuex";
import FormDesign from "../../../pages/formRenderDemo";
export default {
  name: "CommonTemplate",
  props: { templateData: Array, isQuick: { type: Boolean, default: false } },
  components: { FormDesign },
  data() {
    const self = this;
    return {
      changeFlag: false,
      testData: [],
      hostGroupList: [],
      hostList: [],
      controllerList: [],
      initFormFiledFlag: false,
      labelCol: {
        xs: { span: 24 },
        sm: { span: 5 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 19 },
      },
      selectedTemplateObj: { name: "" },
      formItemLayoutWithOutLabel: {
        wrapperCol: {
          xs: { span: 24, offset: 0 },
          sm: { span: 19, offset: 5 },
        },
      },
      form: this.$form.createForm(this, {
        onValuesChange: function (props, fileds) {
          if (self.initFormFiledFlag) {
            self.initFormFiledFlag = false;
            return false;
          }
          self.syncFormdata(fileds);
        },
      }),
    };
  },
  watch: {
    "task.selectTemplateIndex": {
      handler(val) {
        if (
          this.task &&
          this.task.taskInfo &&
          this.task.taskInfo.taskTemplateDetail &&
          this.task.taskInfo.taskTemplateDetail.operations &&
          this.task.taskInfo.taskTemplateDetail.operations.length > 0
        ) {
          this.selectedTemplateObj = this.task.taskInfo.taskTemplateDetail.operations[val] || { name: "", description: "" };
          if (this.selectedTemplateObj && this.selectedTemplateObj.model) this.model = this.selectedTemplateObj.model;
          this.initFormFiled()
        }
      },
      immediate: true,
    },
    templateData: {
      handler(val) {},
      deep: true,
      immediate: true,
    },
  },
  computed: {
    ...mapState({
      task: (state) => state.task, //深拷贝的意义在于watch里面可以在Watch里面监听他的newval和oldVal的变化
    }),
  },
  methods: {
    ...mapActions("task", ["setTaskInfo"]),
    initFormFiled () {
      this.initFormFiledFlag = true
      const formObj = {
        name: this.selectedTemplateObj.name
      }
      let keys = Object.keys(formObj)
      this.form.getFieldsValue(keys)
      this.form.setFieldsValue(formObj)
    },
    syncFormdata(fileds) {
      const currentIndex = this.task.selectTemplateIndex;
      const taskInfo = _.clone(this.task.taskInfo);
      const taskTemplateDetail = taskInfo.taskTemplateDetail;
      const operations = taskTemplateDetail.operations;
      for (var key in fileds) {
        // 名字比较特殊 特殊处理
        if (key === "name") {
          operations[currentIndex].name = fileds[key];
        }
      }
      this.setTaskInfo(taskInfo);
    },
    // 获取主机组
    getGroupHost() {
      const params = {
        page_size: 10000,
        page: 1,
        keyword: "",
      };
      this.$axiosGet(global.API.getHostGroups, params).then((res) => {
        if ([200, 201, 204, 202].includes(res.status)) {
          this.hostGroupList = res.data.results;
        }
      });
    },
    // 获取主机
    getHosts() {
      const params = {
        page_size: 1000,
        page: 1,
        keyword: "",
      };
      this.$axiosGet(global.API.getHostList, params).then((res) => {
        if ([200, 201, 204, 202].includes(res.status)) {
          this.hostList = res.data.results;
        }
      });
    },
  },
  created() {
    this.getGroupHost();
    this.getHosts();
  },
  beforeDestroy() {},
};
</script>
<style lang="less" scoped>
.common-template {
  // 头部
  .drag-template-item {
    margin-top: 16px;
    margin-bottom: 30px;
    display: flex;
    position: relative;
    align-items: center;
    z-index: 1;
    border: 1px solid transparent;
    padding: 0 0 0 14px;
    img {
      height: 42px;
      margin: 0px 20px 0 0;
      width: 42px;
    }
    .action-content {
      display: flex;
      width: 100%;
      flex-direction: column;
      justify-content: space-between;
    }
    .action-text-wrap {
      width: 100%;
      height: 24px;
      line-height: 24px;
      margin-bottom: 4px;
      white-space: nowrap;
      .action-label {
        display: inline-block;
        font-weight: 700;
        max-width: 65%;
        font-size: 16px;
        color: #1d202d;
        letter-spacing: 0;
        font-weight: 600;
        margin-right: 10px;
        min-width: 10px;
      }
    }
    .action-desc {
      font-size: 14px;
      color: #6f737d;
      letter-spacing: 0;
      font-weight: 400;
    }
  }
  .mgh160 {
    // margin: 0 110px;
  }
  .form-content {
    max-height: 506px;
    overflow-y: auto;
    padding: 0 22px 0 48px;
    .reduce-icon {
      cursor: pointer;
      font-size: 18px;
      color: @primary-color;
      margin-left: 6px;
    }
    .form-multiple-item:last-child {
      margin-bottom: 12px;
    }
    .form-item-container {
      position: relative;
      // margin-bottom: 6px;
      .filed-name-tips {
        display: flex;
        align-items: center;
        position: absolute;
        font-size: 12px;
        top: 32px;
        left: 12px;
        width: 20%;
        &-word {
          display: inline-block;
          max-width: calc(100% - 0px);
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
      .filed-name-tips-icon {
        cursor: pointer;
      }
      .slider-icon {
        position: relative;
        top: -28px;
      }
    }
  }
  .qiick-content {
    max-height: 660px;
  }
  .ant-collapse-borderless {
    background: #fff;
    .ant-collapse-item {
      border-bottom: none;
    }
  }
  .ant-collapse > .ant-collapse-item > .ant-collapse-header {
    padding: 0px;
  }
}
</style>
<style lang="less">
#common-template {
  .genFromComponent {
    border: none;
    padding: 0px 16px 0 48px;
    .el-form-item__label {
      width: 20.8% !important;
    }
    .el-form-item__content {
      margin-left: 20.8% !important;
    }
  }
}
</style>