<!--
 * @Author: mjzhu
 * @Date: 2022-06-13 14:04:05
 * @LastEditTime: 2022-09-05 17:12:27
 * @FilePath: \awx-ui\src\pages\quickaccess\quickDetail.vue
-->
<template>
 <a-spin :spinning="spinning">
  <div class="qiuck-detail">
    <div class="qiuck-detail-form">
      <common-form ref="commonFormRef" v-if="!task.showStepsList" :isQuick="true" />
    </div>
    <div class="footer-save">
      <!-- <a-button class="mgr10" type="primary" :loading="nextLoading" @click="save('save')">保存</a-button> -->
      <a-button class="mgr10" type="primary" :loading="nextRunLoading" @click="validte('publish')">保存并执行</a-button>
    </div>
  </div>
 </a-spin>

</template>
<script>
import { mapState, mapMutations, mapActions } from "vuex";
import CommonForm from "@/components/steps/components/commonForm.vue";
export default {
  name: "operationBox",
  components: { CommonForm },
  watch: {},
  data() {
    return {
      stepsType: "newcreatetemplate",
      interval: 0,
      currentSteps: 1,
      nextLoading: false,
      nextRunLoading: false,
      spinning: false
    };
  },
  computed: {
    ...mapState({
      task: (state) => state.task, //深拷贝的意义在于watch里面可以在Watch里面监听他的newval和oldVal的变化
    }),
  },
  methods: {
    ...mapActions("task", [
      "setTaskInfo",
      "setShowStepsList",
      "setSelectTemplateIndex",
    ]),
    getEditorValue () {
      const value = this.$refs.commonFormRef ?  this.$refs.commonFormRef.getEditorValue() : ''
      return value
    },
    validte (type) {
      this.$refs.commonFormRef.form.validateFields((err) => {
        if (!err) {
          this.save(type)
        }
      })
    },
    async save(type) {
      const self = this;
      let param = _.cloneDeep(this.task.taskInfo);
      if (
        !(
          param.taskTemplateDetail &&
          param.taskTemplateDetail.operations &&
          param.taskTemplateDetail.operations.length > 0
        )
      ) {
        this.$message.warning("步骤尚未执行");
        return false;
      }
      const editorValue = this.getEditorValue()
      param.taskTemplateDetail.operations.forEach(element => {
        if (element.type === 'CodeText') {
          element.defalutValue = editorValue
        }
      });
      param.operations =  param.taskTemplateDetail.operations
      param.configs = param.taskTemplateDetail.configs;
      delete param.taskTemplateDetail;
      // param.project_id = '04e45c2a-0505-4423-b8fc-429b9257ee4c'
      this.nextLoading = true;
      let res = null;
      if (this.task.taskInfo.task_id) {
        res = await this.$axiosPut(
          global.API.curdTasks + `${this.task.taskInfo.task_id}`,
          param
        );
      } else {
        res = await this.$axiosJsonPost(global.API.curdTasks, param);
      }

      this.nextLoading = false;
      if ([200, 201, 204, 202].includes(res.status)) {
        console.log(res, "我保存之后的数据");
        const taskInfo = {
          ...res.data,
          taskTemplateDetail: {
            operations: res.data.operations,
          },
        };
        if (!this.task.taskInfo.task_id) this.setTaskInfo(taskInfo);
        if (type === "publish") {
          self.savePublish(this.task.taskInfo.task_id);
          return false;
        }
        this.$message.success("保存成功");
      }
    },
    async savePublish(taskId) {
      // 这个执行 调用哪个接口
      let res = await this.$axiosJsonPost(
        global.API.curdTasks + `${taskId}/publish_async?executes=start`,
        {}
      );
      if ([200, 201, 204, 202].includes(res.status)) {
        this.$message.success("提交成功");
        // this.closeModal();
      }
      console.log(res, "执行之后的数据");
    },
    curdTaskTemplate() {
      const id = this.$route.params.quickId;
      this.spinning = true;
      this.$axiosGet(global.API.curdTaskTemplate + id + "/", {}).then((res) => {
        this.spinning = false;
        if (
          [200, 201, 204, 202].includes(res.status) &&
          res.data &&
          res.data.operations &&
          res.data.operations.length > 0
        ) {
          this.taskTemplateDetail = res.data;
          this.setTaskInfo({
            ...res.data,
            taskTemplateDetail: {
              operations: res.data.operations,
            },
          });
          this.setShowStepsList(false);
          this.setSelectTemplateIndex(0);
        } else {
          this.setTaskInfo({
            ...this.task.taskInfo,
            taskTemplateDetail: {},
          });
          this.setShowStepsList(true);
          this.setSelectTemplateIndex(0);
        }
      });
    },
  },
  mounted() {
    // this.curdTaskTemplate();
  },
  activated () {
    this.curdTaskTemplate();
  },
  deactivated () {
    this.setSelectTemplateIndex(null);
    this.setTaskInfo({});
  }
};
</script>
<style lang="less" scoped>
.qiuck-detail {
  height: calc(100vh - 126px);;
  padding: 20px;
  background: #fff;
  .common-template {
    width: 60%;
  }
  &-form {
    display: flex;
    justify-content: center;
  }

  .footer-save {
    position: absolute;
    bottom: 10px;
    right: 0;
    left: 0;
    // width: 1300px;
    height: 64px;
    background: rgba(242, 244, 247, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    button {
      min-width: 86px;
    }
    /deep/
      .ant-btn.ant-btn-loading:not(.ant-btn-circle):not(.ant-btn-circle-outline):not(.ant-btn-icon-only) {
      padding-left: 20px;
    }
  }
}
</style>